@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css';

@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
//@import "../node_modules/intl-tel-input/build/css/intlTelInput.min.css";
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-kanban/styles/material.css';

@import "../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css";

@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';



@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inplace-editor/styles/material.css';

/* You can add global styles to this file, and also import other style files */
/*@import '../src/assets/styles/bootstrap.css';*/


html,
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  user-select: none;
}

a,
.btn-link {
  color: #0366d6;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

app {
  position: relative;
  display: flex;
  flex-direction: column;
}

.top-row {
  height: 3.5rem;
  display: flex;
  align-items: center;
}

.main {
  flex: 1;
}

.main .top-row {
  background-color: #f7f7f7;
  border-bottom: 1px solid #d6d5d5;
  justify-content: flex-end;
}

.main .top-row>a {
  margin-left: 1.5rem;
}

.sidebar {
  background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
}

.sidebar .top-row {
  background-color: rgba(0, 0, 0, 0.4);
}

.sidebar .navbar-brand {
  font-size: 1.1rem;
}

.sidebar .oi {
  width: 2rem;
  font-size: 1.1rem;
  vertical-align: text-top;
  top: -2px;
}

.nav-item {
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
}

.nav-item:first-of-type {
  padding-top: 1rem;
}

.nav-item:last-of-type {
  padding-bottom: 1rem;
}

.nav-item a {
  color: #d7d7d7;
  border-radius: 4px;
  height: 3rem;
  display: flex;
  align-items: center;
  line-height: 3rem;
}

.nav-item a.active {
  background-color: transparent;
  color: white;
}

.nav-item a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.content {
  padding-top: 1.1rem;
}

.navbar-toggler {
  background-color: rgba(255, 255, 255, 0.1);
}

.valid.modified:not([type=checkbox]) {
  outline: 1px solid #26b050;
}

.invalid {
  outline: 1px solid red;
}

.validation-message {
  color: red;
}

.planner-header .sb-table-cell {
  padding-right: 6px;
  vertical-align: middle;
}

.planner-header .sb-github-btn {
  width: 40px;
  opacity: 100;
  padding: 11px 8px;
  border-radius: 0px;
  border-width: 0px;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
  font-weight: 500;
  line-height: 18px;
  border-color: transparent;
  background: transparent;
}

.planner-header .github-image img {
  filter: brightness(0) invert(100%);
}

.create-product-table {
  width: 100%;
  opacity: 100;
  padding: 11px 8px;
  border-radius: 10px;
  border: solid 1px black;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
  font-weight: 500;
  line-height: 18px;
}


@media (max-width: 767.98px) {
  .main .top-row {
    display: none;
  }
}

@media (min-width: 768px) {
  app {
    flex-direction: row;
  }

  .sidebar {
    width: 250px;
    height: 100vh;
    position: sticky;
    top: 0;
  }

  .main .top-row {
    position: sticky;
    top: 0;
  }

  .main>div {
    padding-left: 2rem !important;
    padding-right: 1.5rem !important;
  }

  .navbar-toggler {
    display: none;
  }

  .sidebar .collapse {
    /* Never collapse the sidebar for wide screens */
    display: block;
  }
}

.nav-item.e-list-item {
  height: 50px;
  line-height: 50px;
  padding: 0px;
  border-radius: 3px;
  border-bottom: 0px;
  align-items: center;
  color: #666;
  cursor: pointer;
}

.nav-item.e-list-item:hover {
  background-color: #f5f5f5;
  border-color: transparent;
  color: rgba(51, 51, 51, .87);
}

ul.nav.flex-column {
  margin: 0 16px;
  width: 210px;
}

.sideparent.menulist {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "-apple-system", BlinkMacSystemFont;
  font-size: 12px;
  font-weight: 400;
}


#Loginbuttons {
  justify-content: space-between;
  padding-top: 20%;
  text-align: center;
}

.Google {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 19px;
  background-color: #FFFFFF;
  border: none;
  color: white;
  text-decoration: none;
  display: flex;
  font-size: 2vh;
  margin: 4px 2px;
  border-radius: 13px;
  width: 90%;
  height: 8vh;
  color: black;
  text-align: left;
  padding-left: 2em;
}

.Facebook {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 19px;
  background-color: #3A559F;
  text-decoration: none;
  display: flex;
  justify-content: space-between; /* Added */
  align-items: center;
  font-size: 2vh;
  margin: 4px 2px;
  border-radius: 13px;
  width: 90%;
  height: 8vh;
  color: black;
  padding: 0 2em;
}

.fa-facebook-square {
  color: white;
  font-size: 4vh;
}

.statustemp {
  position: relative;
  height: 19px;
  border-radius: 15px;
  text-align: center;
  background-color: #C3F1D0;
  color: #00752F;
  width: 47px;
}
.statustemp.e-lowEng {
  background-color: #FFDBDB;
  color: #BD0000;

}

td.e-rowcell .statustxt {
  color: #398120;
  position: relative;
}

td.e-rowcell .statustxt.e-lowEng {
  color: #e60000;
  position: relative;
}

.property-panel-content .e-filter-table {
  width: 100%;
}

.property-panel-content .e-filter-label {
  width: 30%
}

.property-panel-content .e-reset {
  padding-top: 13px;
  text-align: center;
}

.property-panel-content td {
  padding-top: 10px;
}

.property-panel-header {
  padding-bottom: 0px;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
